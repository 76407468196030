import React from "react"

import PageTitle from "../components/page-title"
import SEO from "../components/seo"

export default function ContactIndex({data}) {

    return(

        <>
            <SEO
                title="Contact"
                description="Find out how to get in contact with PKD Tech."
            />

            <PageTitle summary="Our dedicated sales experts are ready to assist with your every requirement."
                       title="Get in touch"/>

            <div className={"mb-10 lg:mb-32 w-full xl:w-1/2 xl:mx-auto px-6 md:px-12 xl:px-0"}>

                <div className={"rounded-md shadow-lg bg-white border-gray-100"}>
                    <div className={"rounded-md shadow-xs overflow-hidden p-8"}>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className={"prose prose-lg"}>
                                <h2 className="text-base leading-6 text-primary font-sans font-black uppercase">Email</h2>
                                <p className={"mb-4 md:mb-0"}><a href="mailto:sales@pkdtech.co.uk">sales@pkdtech.co.uk</a></p>
                            </div>
                            <div className={"prose prose-lg"}>
                                <h2 className="text-base leading-6 text-primary font-sans font-black uppercase">Telephone</h2>
                                <p><a href="tel:+441983563763">+44 (0)1983 563 763</a></p>
                            </div>
                            <div className={"prose prose-lg"}>
                                <h2 className="text-base leading-6 text-primary font-sans font-black uppercase">Address</h2>
                                <p className={"prose-sm"}>Little Grove Lower Road<br/>
                                    Adgestone, Sandown<br/>
                                    Isle of Wight<br/>
                                    PO36 0HN<br/>
                                    United Kingdom</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </>
    )
}
